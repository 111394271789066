import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import SearchResultFilter from "./SearchResultsFilter.jsx";
import SearchMainContent from "./SearchMainContent";
import { useSelector, useDispatch } from "react-redux";
import {
  getWavDetails,
  getWavs,
  searchWavs,
} from "../../redux/actions/wavActions";

import SearchSideBar from "./SearchSideBar";
import { CLEAR_FILTERS, GET_WAVS } from "../../redux/constants/constants";
import { useLocation } from "react-router-dom";
import { filterWavsAccordingToPriceRange, getCheapestAndCostliestWavPrice } from "../../Helpers/utils";



const SearchAndBuy = () => {
  const [wavs, setWavs] = useState([]);
  const [searched, setSearched] = useState([]);
  const detailsFromState = useSelector((state) => state.wavs.details);
  const wavsFromState = useSelector((state) => state.wavs.list);
  const searchedState = useSelector((state) => state.wavs.searched);
  const [body, setBody] = useState("");
  // const [searchValue, setSearchValue] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [mileage, setMileage] = useState("");
  const [size, setSize] = useState("");
  const [transmission, setTransmission] = useState("");
  const [seating, setSeating] = useState("");
  const [wheelchairs, setWheelchairs] = useState("");
  const [entrance, setEntrance] = useState("");
  const [doors, setDoors] = useState("");
  const [year, setYear] = useState("");
  const [price, setPrice] = useState("");
  // const [minPrice, setMinPrice] = useState(0);
  // const [maxPrice, setMaxPrice] = useState(1000000);
  const [priceRange, setPriceRange] = React.useState([0, 100000]);
  const [priceSliderRange, setPriceSliderRange] = React.useState([0, 100000]);
  const [activeWavs, setActiveWavs] = useState([])
  const [filtered, setFiltered] = useState([])
  const [wavType, setWavtype] = useState('')

  const dispatch = useDispatch();
  const location = useLocation()



  useEffect(() => {
    if (wavsFromState) {
      let activeWavsList = wavsFromState.filter((ele) => ele.status == "active")
      setActiveWavs(activeWavsList)

      setFiltered(activeWavsList)
      console.log("filtered=", activeWavsList, activeWavsList.length)

      if (activeWavsList.length > 0) {
        let { lowestVal, highestVal } = getCheapestAndCostliestWavPrice(activeWavsList)
        console.log("lowestVal, highestVal", lowestVal, highestVal)
        setPriceSliderRange([lowestVal, highestVal])
        // !location.state && !location.state.priceRange && setPriceRange([lowestVal, highestVal])
      }


    }
  }, [wavsFromState])

  // console.log("filtered=", filtered.length)



  useEffect(() => {
    if (location && location.state && location.state.priceRange) {
      setPriceRange(location.state.priceRange)

    }
  }, [location])

  const searchedWords = useSelector((state) => state.wavs.searchedWords);
  console.log("searchedWords", searchedWords)


  const [wavsList, setWavsList] = useState([])



  const handleFilterChange = (make, model, priceRange) => {
    setWavsList([])
    let wavsFiltered = []
    if (make && make != "") {
      wavsFiltered = (searchedState.length > 0 ? searchedState : activeWavs).filter((wav) => wav.make.trim().toLowerCase() === make.trim().toLowerCase())
    }
    if (make == "") {
      wavsFiltered = (searchedState.length > 0 ? searchedState : activeWavs)
    }
    if (model != "") {
      wavsFiltered = make !== ""
        ? wavsFiltered && wavsFiltered.filter((wav) => wav.models.trim().toLowerCase() === model.trim().toLowerCase())
        : (searchedState.length > 0 ? searchedState : activeWavs) &&
        (searchedState.length > 0 ? searchedState : activeWavs).filter((wav) => wav.models.trim().toLowerCase() === model.trim().toLowerCase());
    }
    if (priceRange) {
      let clonewavsFiltered = filterWavsAccordingToPriceRange(wavsFiltered, priceRange)
      wavsFiltered = clonewavsFiltered
    }
    setWavsList(wavsFiltered)
  }



  useEffect(()=>{
    handleFilterChange(make, model, priceRange)
    console.log("activeWavs", activeWavs)
  }, [activeWavs])



  useEffect(() => {
    if (searchedWords && searchedWords.length > 0) {
      searchedWords.forEach((ele) => {
        if (ele.name == "body") {
          setBody(ele.value)
        }
        else if (ele.name == "make") {
          setMake(ele.value)
          handleFilterChange(ele.value, model, priceRange)
        }
        else if (ele.name == "model") {
          setModel(ele.value)
          handleFilterChange(make, ele.value, priceRange)

        }
        else if (ele.name == "mileage") {
          setMileage(ele.value)
        }
        else if (ele.name == "transmission") {
          setTransmission(ele.value)
        }
        else if (ele.name == "seating") {
          setSeating(ele.value)
        }
        else if (ele.name == "wheelchairs") {
          setWheelchairs(ele.value)
        }
        else if (ele.name == "entrance") {
          setEntrance(ele.value)
        }
        else if (ele.name == "doors") {
          setDoors(ele.value)
        }
        else if (ele.name == "year") {
          setYear(ele.value)
        }
        else if (ele.name == "price") {
          setPrice(ele.value)
        }
        else if (ele.name == "wavType") {
          setWavtype(ele.value)
        }
      })
    }
  }, [searchedWords])


  useEffect(() => {
    console.log("make=", make, "model=", model, "priceRange=", priceRange)
    handleFilterChange(make, model, priceRange)
  }, [make, model, priceRange])

  const search = () => {
    dispatch({ type: GET_WAVS, payload: [] })
    dispatch(
      searchWavs(
        body,
        make,
        model,
        mileage,
        size,
        transmission,
        seating,
        entrance,
        doors,
        year,
        "",
        [],
        "",
        wheelchairs,
        wavType
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(searchWavs(""))
    dispatch(getWavs());
    dispatch(getWavDetails());
  }, []);

  useEffect(() => {
    setWavs(wavsFromState);
  }, [wavsFromState]);

  // useEffect(() => {
  //   setSearched(searchedState);
  // }, [searchedState]);

  // const filteredWavs =
  //   searchedState &&
  //   wavs.filter((wav) => wav.models.trim().toLowerCase().includes(searchValue));
  // const filtered =
  //   searchedState &&
  //   // searchedState.length > 0 &&
  //   searchedState.filter((wav) => wav.status === "active");

  useEffect(() => {
    if (searchedState && searchedState.lenght > 0) {
      let cloneWavs = searchedState.filter((wav) => wav.status === "active")
      setFiltered(cloneWavs)
    }
  }, [searchedState])

  const [filteredByMake, setFilteredByMake] = useState([])
  const [filteredByModel, setFilteredByModel] = useState([])

  // const filteredByMake =

  // filtered.filter((wav) => wav.make === make);

  // const filteredByModel =
  //   make !== ""
  //     ? filteredByMake.filter((wav) => wav.models === model)
  //     :
  //     // allWavs.length > 0 &&
  //     filtered.filter((wav) => wav.models === model);

  const filteredByPriceSlider =
    filtered &&
    // filtered.length > 0 &&
    filtered.filter((wav) => {
      let price = 0
      if (Object.entries(wav).length > 0) {
        wav.price_2 = wav.price.replace(/[^0-9]/g, "")
        price = parseFloat(wav.price_2);
      }
      if (price >= priceRange[0] && price <= priceRange[1]) {
        return wav;
      }
    });

  const resetFilters = () => {
    setBody('')
    setMake('')
    setModel('')
    setMileage('')
    setSize('')
    setTransmission('')
    // setSearchValue('')
    setSeating('')
    setEntrance('')
    setDoors('')
    setYear('')
    setPrice('')
    // setArchive('')
    setPriceRange(priceSliderRange)
    setWheelchairs("")


    dispatch(searchWavs(""));
    dispatch({ type: CLEAR_FILTERS });

  }

  const refToTop = useRef()


  console.log("filteredByPriceSlider", filteredByPriceSlider)

  // const wavsList =
  //   make == "" && model == ""
  //     ? filtered
  //     :
  //     make !== "" && model === ""
  //       ? filteredByMake
  //       : model !== ""
  //         ? filteredByModel
  //         :
  //         filtered;

  return (
    <div className="search-result" ref={refToTop}>
      <div className="search-nav">
        {/* <div className="logo">WAVCompare</div> */}

        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Buy your next new or used WAV</h1>

          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="sidebar">
          <SearchSideBar
            setBody={setBody}
            setMake={(e) => {
              setMake(e)
              handleFilterChange(e, model, priceRange)
            }}
            setModel={(e) => {
              setModel(e)
              handleFilterChange(make, e, priceRange)
            }}
            setMileage={setMileage}
            setSize={setSize}
            setTransmission={setTransmission}
            setSeating={setSeating}
            setEntrance={setEntrance}
            setDoors={setDoors}
            setYear={setYear}
            setPrice={setPrice}
            detailsFromState={detailsFromState}
            search={search}
            wavType={wavType}
            setWavtype={setWavtype}
            // searchValue={searchValue}
            // setSearchValue={setSearchValue}
            // minPrice={minPrice}
            // maxPrice={maxPrice}
            // setMinPrice={setMinPrice}
            // setMaxPrice={setMaxPrice}
            wavs={filtered}
            allWavs={activeWavs}
            make={make}
            model={model}
            body={body}
            year={year}
            doors={doors}
            entrance={entrance}
            seating={seating}
            size={size}
            mileage={mileage}
            transmission={transmission}
            price={price}
            priceRange={priceRange}
            setPriceRange={(e) => {
              setPriceRange(e)
              handleFilterChange(make, model, e)
            }}
            resetFilters={resetFilters}
            priceSliderRange={priceSliderRange}
            // setPriceSliderRange={setPriceSliderRange}
            filtered={filtered}
            setFiltered={setFiltered}
            filteredByMake={filteredByMake}
            setFilteredByMake={setFilteredByMake}
            filteredByModel={filteredByModel}
            setFilteredByModel={setFilteredByModel}
            handleFilterChange={handleFilterChange}
            setWheelchairs={setWheelchairs}
            wheelchairs={wheelchairs}
            wavsList={wavsList}
          />
        </div>
        <div className="main-content">
          {/* {wavsList.length > 0 ? (
            <SearchMainContent
              wavs={wavsList}
              // searchValue={searchValue}
              length={wavsList.length}
              refToTop={refToTop}
            />
          ) : ( */}
          <SearchMainContent
            wavs={wavsList}
            // searchValue={searchValue}
            length={wavsList.length}
            refToTop={refToTop}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default SearchAndBuy;
